<template>
  <div v-if="topic" class="row p-fluid grid formgrid">
    <h4>- {{ topicName? topicName: 'TOPIC NOT SELECTED' }}</h4>
    <div 
      style="
        display: flex; 
      "
    >
      <DocumentTopicProgram 
        :programs="programs" 
        :selectedProgram="newForm.program" 
        :topic="topic"
        :index="index"
        @updateProgram="updateProgram" 
        @updateCreatedProgram="updateCreatedProgram" 
        @saveDocument="saveDocument"
        @redirectProgram="redirectProgram"
        @syncCountryCompanyWithProgram="syncCountryCompanyWithProgram"
      />
      <div 
        class="row" 
        v-if="segmentations.length > 0" 
        style="
          margin-left: 1rem;
          /* width: 100%; */
        "
      >
        <div 
          v-for="(segmentation, index) in segmentations" 
          :key="segmentation.segmentationId" 
          class="col my-3"
        >
          <Divider align="right"  class="ml-5" style="min-width: 10rem;">
            <div class="inline-flex align-items-center">
              <span class="p-tag" style="white-space: nowrap;" > {{ segmentation.name }}</span>
            </div>
          </Divider>
          <template v-if="segmentation.isRegional">
            <div 
              style="
                min-width: 10rem;
              " 
              v-if="regionCountries2[index]"
            >
              {{getCountryName(getCountries(index), regionCountries2[index])}}
            </div>
            <div v-else style="color: red;">
              Not assigned
            </div>
          </template>

          <template v-else>
            <div 
              style="
                min-width: 10rem;
              " 
              v-if="newForm.segmentationItems[index]"
            >
              {{getSegmName(newForm.segmentationItems[index])}}
            </div>
            <div v-else style="color: red;">
              Not assigned
            </div>
          </template>
        </div>
      </div>
      <h4 v-else>NO SEGMENTATION DEFINED</h4>
      <!--  -->

    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import DocumentTopicProgram from './DocumentTopicProgram';
import Divider from 'primevue/divider';
import { getSegmentations } from '../../../../segmentation/services/segmentation.service'
import { getProgramsByTopicId } from '../../../../program/services/program.service'

export default {
  name: 'DocumentTopic',
  components: {
    DocumentTopicProgram,
    Divider,
  },
  setup: () => ({ v$: useVuelidate() }),
  emits: ['updateTopic','saveDocument','syncCountryCompanyWithProgram','redirectProgram'],
  props: ['topic', 'topics', 'isEditDocumentTopic', 'marketValues','index'],
  computed: {
    topicName() {
      if (this.topics.length > 0) {
        const topic = this.topics.find((t) => t.topicId == this.topic.topicId);
        return topic?.name;
      }
      return '';
    },
    selectedProgramContext(){ //from programs documents list
      return this.$store.getters.obtainSelectedProgram;
    },
  },
  async mounted() {
    await this.getPrograms();
    await this.getSegmentations();
    if (this.isEditDocumentTopic) {
      if(this.topic.documentProgramId){
        this.newForm = {
          topicId: this.topic.topicId,
          program: this.topic.documentProgram,
          documentProgramId: this.topic.documentProgramId,
          segmentationItems: [],
          regionCountries: [],
        };
      }
    }
    if(this.selectedProgramContext){
      this.newForm.documentProgramId = this.selectedProgramContext
      this.newForm.program = {
        documentProgramId: this.selectedProgramContext,
        name: null
      }
      this.$store.dispatch('cleanSelectedProgram');   // clean SelectedProgram          
    }
  },
  data() {
    return {
      newForm: {
        topicId: this.topic.topicId,
        program: {
          documentProgramId: null,
          name: null,
        },
        documentProgramId: null,
        segmentationItems: [],
        regionCountries: [],
      },
      regionCountries2: [],
      segmentations: [],
      programs: null,
    };
  },
  validations() {
  },
  methods: {
    getSegm(){
      if(this.programs && this.newForm.program.documentProgramId != null){
        // if(this.programs[0].documentProgramTopics[0].docProgramTopicSegItems.length > 0){
          const a =  this.programs.filter(program => program.documentProgramId === this.newForm.program.documentProgramId )[0].documentProgramTopics[0].docProgramTopicSegItems
          if(a.length>0){
            const b = a.filter(segm => segm.regionCountry != null)[0].regionCountry.countryId
            this.newForm.segmentationItems= []
            this.regionCountries2[0] = b
            this.newForm.segmentationItems[0] = b
            const c = a.filter(segm => segm.regionCountryId == null)
            c.map(segm => this.newForm.segmentationItems.push(segm.segmentationItemId))
          }
          else{
            this.newForm.segmentationItems= []
            this.regionCountries2 = [] 
          }
          
        // }
      } 
    },

    updateCreatedProgram(programData) {
      if(this.programs === null){
        this.programs = []
        this.programs.push(programData)
      }
      else{
        this.programs.push(programData)
      }
      const newProgram = { 'documentProgramId': programData.documentProgramId }
      this.updateProgram(newProgram)
    },
    updateProgram(program) {
      const programIndex = this.programs.findIndex(
        (p) => p.documentProgramId == program.documentProgramId
      );
      const programData = this.programs[programIndex];
      this.newForm.program = {
        'documentProgramId': programData.documentProgramId,
        'name': programData.name,
        // ...programData,
      };
      this.newForm.documentProgramId = programData.documentProgramId
    },
    async getPrograms() {
      try {
        this.loading = true;
        if (this.topic.topicId) {
          let programOrdered = await getProgramsByTopicId(this.topic.topicId , true, true, false) //getProgramsByTopicId(topicId , withDetails , withSegItems , totalDocuments){

          programOrdered.sort(function (a,b){
            if(a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
            if(a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
            return 0;
          }); 

          this.programs = programOrdered
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    async getSegmentations() {
      try {
        this.loading = true;
        // await this.$store.dispatch('obtainSegmentations', this.topic.topicId);
        this.segmentations = await getSegmentations(this.topic.topicId);
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    getCountries(index) {
      let countriesList = []
      // this.segmentations.filter(se => se.isRegional)[index].segmentationItems.map(si => countriesList.push( si.region.countries ))
      this.segmentations.filter(se => se.isRegional)[index].segmentationItems.map(si => si.region.regionCountries.map(rc => countriesList.push(rc.country)))
      return countriesList.flat()
    },
    prepareForSend(index, event) { //prepare the segmentation to send regionCountries
      let e = ""
      if (event.value) {
        e = event.value
      }
      else {
        e = event
      }
      const segmentationItemId = this.segmentations.filter(se => se.isRegional)[index].segmentationItems.filter(si => si.region.regionCountries.some(c => c.country.countryId === e)).map(filtered => filtered.segmentationItemId)
      const regionId = this.segmentations.filter(se => se.isRegional)[index].segmentationItems.filter(si => si.region.regionCountries.some(c => c.country.countryId === e)).map(filtered => filtered.region.regionId)

      let regionCountries = {
        countryId: e,
        segmentationItemId: segmentationItemId[0],
        regionId: regionId[0]
      }
      this.newForm.segmentationItems[index] = segmentationItemId[0]
      this.newForm.regionCountries[index] = regionCountries
      this.$emit('updateTopic', this.newForm);
    },
    getCountryName(options, countryId){
      if(countryId){
        const a = options.find(option => option.countryId=== countryId)
        return a.name 
      }
      else{
        return 
      }
    },
    getSegmName(segmentationId){
      const b = this.segmentations.filter(seg => seg.segmentationItems.some(segmItem => segmItem.segmentationItemId === segmentationId) )
      const c =  b[0].segmentationItems.filter(segmItem => segmItem.segmentationItemId === segmentationId )
      return c[0].name
    },

    saveDocument(redirectDocumentProgramTopicId){
      this.$emit('saveDocument',redirectDocumentProgramTopicId);
    },

    redirectProgram(redirectDocumentProgramTopicId){
      this.$emit('redirectProgram',redirectDocumentProgramTopicId);
    },

    syncCountryCompanyWithProgram(companyAndCountry){
      this.$emit('syncCountryCompanyWithProgram', companyAndCountry );
    }
  },
  watch: {
    // 'newForm.program': {
    //   deep: true,
    //   handler() {
    //     this.$emit('updateTopic', {documentTopic: this.newForm, index: this.index});
    //   },
    // },
    'newForm.documentProgramId': {
      deep: true,
      handler() {
        this.getSegm()
        this.$emit('updateTopic', {documentTopic: this.newForm, index: this.index});
      },
    },
    'newForm.segmentationItems': {
      deep: true,
      handler() {
        this.$emit('updateTopic', {documentTopic: this.newForm, index: this.index});
      },
    },
  },
  beforeUnmount(){
    this.$store.dispatch('cleanSelectedProgram');   // clean SelectedProgram
  },
};
</script>

